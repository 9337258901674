import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Scott = () => {
  return (
    <Layout>
      <Head title="Scott Hronich" />
      <h3>Scott Hronich</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        New York
      </h4>
      <p>
        <OutboundLink href="https://internalfireglass.com/">
          Website
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.youtube.com/channel/UChmGVCp6NloHOVmEAOVMUvQ">
          YouTube
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/internalfireglass/">
          Instagram
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.facebook.com/InternalFireGlass">
          Facebook
        </OutboundLink>
      </p>
      <h3>Interview by Edward Alvarez</h3>

      <p>
        Scott Hronich crafts the most beautiful glass spheres I have ever seen.
        His glass sculptures are interesting optical illusions which mystify and
        astound. I spoke with Scott about his journey as a glass sculptor and
        his hopes for the future. In a busy world, there's something special and
        deeply meaningful about Scott's work. Every sphere Scott creates offers
        endless inspiration.
      </p>

      {/* <div className="video-container">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/PS6AYacEFwU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div> */}

      <div style={{ display: "flex", flexDirection: "column" }}>
        <img
          style={{ maxWidth: 350, margin: "35px auto" }}
          src="https://res.cloudinary.com/benjifri/image/upload/v1664069917/artnow/oneoffs/Screen_Shot_2022-09-24_at_6.36.58_PM_copy.png"
          alt="Scott Hronich art"
        />

        <p>
          Edward Alvarez: I think the first time that I heard about you was from
          the magic store Art of Play in San Diego. I remember I first saw the
          galaxy marble and I think what happened was I really wanted to buy
          that one but it was sold out at the time, and then I went to your site
          and I couldn't find the one that I wanted and I ended up buying the
          dichroic swirl. And then ironically after I bought that, Art of Play
          ended up restocking the galaxy one that I wanted. So then I ended up
          buying two of your marbles even though I only planned to buy one.
          They're not exactly the cheapest thing, but after buying both of them,
          they're both really unique and stunning when you look at them in
          different types of light. I marvel at them every time I see them. And
          so, I'm glad that I bought both. Maybe you could talk a little bit
          about those?
        </p>

        <p>
          Scott Hronich: Nice, I didn't know you had a collection. That's nice,
          I appreciate the support and the kind words. That's awesome. I just
          actually did a run of the galaxies, they are one of the main styles
          that I make and I am a big space nerd so I'm really fascinated by the
          feel of them. For those what I do is I sprinkle gold and silver
          filings, little pieces of the metals inside the glass when it's at
          3,000 degrees- and it's just getting those metals to flow into the
          glass to look like a spiral galaxy. It's just a layering and heating
          technique that I've developed, but it gives a neat, interesting feel
          with the stars and the wonderment of space.
        </p>

        <p>
          EA: What you do, is it glass blowing or is it glass making? I'm not
          sure what the proper term is.
        </p>

        <p>
          SH: Yea, I'm kind of at a weird crossroads with that. What I do is
          traditional techniques of glass blowing, flame working. It's a higher
          quality optical glass so it's all what's called torch work, or flame
          working. So it's directly right inside a specially designed torch that
          I heat up the glass, but I don't actually blow anything anymore. I
          used to make hollow vessels and I used to do things. But for the 20
          out of 25 years I've been blowing glass, most of the stuff that I make
          are solid spheres. So I kind of consider myself more of a glass
          sculptor than I do a glass blower.
        </p>

        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1664137570/artnow/scott%20hronich/IFG_studio_working-min.jpg"
          alt="Scott Hronich, flameworker"
        />

        <p>EA: Maybe you could talk a little bit about how you got started?</p>

        <p>
          SH: I started in Colorado 25 years ago. I went to university and I
          studied art there. I was born in New Mexico and raised there, so it
          was a university in New Mexico, and I got through the first two years
          of mostly taking all art. I was a financial aid student, dependent on
          the financial aid, and they caught me in the middle of it and they
          said to proceed any further with getting financial aid you have to
          take these basic courses as well. I was getting a 4.0 in all my art
          classes but I failed at all the the basic stuff. Another year went by
          and they gave me a trial and I messed up, I got my financial aid
          withdrawn. So I never got to graduate from college. But after that I
          went up to Colorado and was working normal jobs, and wanted to get
          back into art. I missed it after working regular jobs for a period of
          time. I found a house up in the mountains that had a glass blowing
          studio in it. I wanted a painting studio, I've always loved oil
          paintings and I always wanted to be the next Salvador Dali. I really
          loved surrealism, I wanted to be able to paint reality in a
          surrealistic way to get across emotions and thoughts and feelings in a
          more in-depth kind of manner. So I went up there and the guy was doing
          this special torch work, glass blowing. And as I cleared out a space
          above a studio, he was doing this glass blowing underneath, which was
          25 years ago, this is still pretty underground stuff, and it was even
          more so. He happened to get in a fight with his apprentice about, I
          don't know four or five months after living there at the house, and he
          asked me if I was interested and I was interested. I'd go down and
          watch him blow glass and I was fascinated. When I was in the
          university I played with every medium I could get my hands on. I
          really love human figure drawing, sculpture was another big favorite
          of mine, but I also did photography, I did the traditional graphic
          arts where you make your own font, you know, you hand draw everything,
          and I did graphic art on the computer as well, I did a little bit of
          everything I could get my hands on. So I was excited to touch another
          material, get involved and see what would happen, just seeing what its
          abilities were. I ended up taking an apprenticeship with him that
          didn't last very long. Sadly to say, a really nice guy, really
          heartfelt guy, but he ended up having a drug problem that really
          affected our relationship. So I wasn't there very long. I went off
          after a short time of the apprenticeship and, basically what I like to
          say is, I learned what the materials are, where to buy them, and how
          to start. And so that's what I did, I went off on my own and I started
          teaching myself, just by watching a material and playing with it,
          heating it up, moving it around, seeing what I could do with the
          limited knowledge that I had of its basic workings. So it was a little
          rough beginning, it was interesting, and had a lot to do with the
          underground culture of this type of glass, and a lot of personal
          choices led to the beginning of this journey as well.
        </p>

        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1664137566/artnow/scott%20hronich/IFG_shelf-sml05-min.jpg"
          alt="Scott Hronich art display"
        />

        <p>
          EA: Did you say you were taking an art class that you didn't pass?
        </p>

        <p>
          SH: No...in my art classes I was a 4.0 student. It was the basic
          courses, you had to have so many English, so many Maths, so many
          whatever classes, to get a degree. It wasn't an art college. If I was
          in an art college I would have been okay, but it was a university that
          had an Art program. So I was trying to major in 2D and
          three-dimensional art, which is fine because now what I'm doing in
          glass, I've made my living off glass for 25 years off of everything I
          make. Glass is so three-dimensional and sculpture as it is. So I hit
          my sculpture major there, but the colors in glass and the importance
          of light and the feel of glass is like painting. So I feel like I've
          hit my goals with both of those, but I was just a bad student as far
          as disciplining myself to do the homework that was needed to get the
          basic grades...I was always good at math. I enjoyed math, but I also
          was a person that wasn't diagnosed with dyslexia, but I had severe
          dyslexia. I was way behind in reading, my reading level was really
          low, I couldn't keep up with college level reading and assignments, so
          it affected my college career.
        </p>

        <p>
          EA: I see, well it's interesting that you still found what you were
          good at and you were able to succeed at that. That the failures or the
          missteps that you experienced didn't completely stop your progress in
          life, I thought that's interesting.
        </p>

        <p>
          SH: Yea, I've always felt lucky that I've been able to do this for a
          living. I feel like, how people say, "your ship comes in." I did find
          something that I enjoy doing, challenged me. I kind of needed
          something to be mine, I was kind of lazy before, I just wasn't
          motivated or interested in much. So when I found this, it was
          interesting to me, I found a way to make it mine, since it was mine, I
          nourished and really took good care of it. And I'm fortunate to have
          your support and other people's support, that it's taking good care of
          me and it's nourished me as well, so I feel very lucky to have it.
        </p>

        <p>
          EA: I was also going to ask you, have you ever injured yourself when
          you're working with glass?
        </p>

        <p>
          SH: I have a couple of times. The reason I'm laughing is, I just
          injured myself playing soccer. I'm on a soccer league and I injured my
          foot. I've hurt myself a lot more in soccer than I ever have
          fortunately playing glass. Yea, you get your normal burns, and you get
          your cuts and things, but I've never had a major injury from glass,
          which is great because everything I do is dangerous. I think right now
          the biggest problem I have and the biggest danger is heat exhaustion.
          For the spheres that I make, I make the largest that I've heard of
          made in the world of borosilicate spheres, and the heat off of them-
          you're talking a sphere that's at 3,000 or 4,000 degrees of glass
          radiating all this temperature off. The light alone will burn your
          skin. It gets really hot, so heat exhaustion right now is probably one
          of my biggest and most dangerous concerns. I can take a cut, I can
          take a burn. I really don't do that that often but right now it's
          mainly the heat. And the biggest thing for glass workers to really
          worry about, and the main number one killer of a glass blower is glass
          dust. I was fortunate enough to have a big brother that is an
          industrial hygienist with an OSHA degree. He knew when I got
          interested in glass blowing, he was finishing his degree and he
          researched glass for me and gave me all the things to worry about to
          keep me healthy. I really owe him as far as knowing how to ventilate,
          how to keep things under control so I don't get hurt. So, lucky to
          have him and his advice on that.
        </p>

        <p>
          EA: I didn't really know that there was a such thing as glass dust,
          although I guess maybe it makes sense.
        </p>

        <p>
          SH: Yea, anytime you break the glass it's gonna get a little in the
          air. But a lot of times most glass blowers use color which also has
          another problem besides the dust, a lot of times it's crushed into
          different gradations, fine powder, chunks. Anytime you're moving that
          around it's going to create a glass dust in the air and you got to be
          careful. But not only that, all the little pieces of broken glass-
          people want to sweep up, they want to clean up, anytime you sweep that
          up it kicks the little particles in the air and that creates a lot of
          danger as far as inhaling it, and it's hard to get out of your lungs
          once it's in there. There's lots of dangers with glass that you just
          have to be careful with. Metals are another one, like I said, I use a
          lot of silver and gold, like the galaxy we were talking about earlier.
          You're heating up these metals until they're the most active state,
          you're changing them from a solid into a gas and that means that
          they're separating into tinier particles and moving around really fast
          and very active. And metals- you have metals in your system but you
          really don't want to have a high number of metals. So ventilation is
          key on everything, keeping those metals away from you. I mean there's
          cadmiums, there's bright colors, there's a lot of things that you have
          to be careful with. My goal is to go as far as I can in this. I want
          to go the distance, so I want to make sure that I'm safe and taken
          care of.
        </p>

        <p>
          EA: Yea, it's definitely something to consider. "Health is wealth" and
          all that stuff.
        </p>

        <p>
          SH: Yes, realizing more and more as I get older, it definitely is.
        </p>

        <p>
          EA: But yea it's funny what you say about injuring yourself more
          playing soccer, that's kind of unexpected.
        </p>

        <p>
          SH: Yea, I enjoy it. And it seems like when I get involved and I like
          something, I kind of go overboard, I push myself at it, and I did that
          yesterday. Yea, I'll be out for a little bit...It seems like you have
          one of the spheres right now in your hands.
        </p>

        <p>
          EA: Yea, I'm just looking at it in the light. The thing is that every
          time I look at it I still don't really know how you make it, even
          though you just explained it. The process, I'm still trying to make
          sense of it, if that makes sense.
        </p>

        <p>
          SH: I go to shows all over the country and I talk to thousands of
          people about them and that's the funny thing. After seeing a wide
          spectrum of people from different places, different ages, different
          everything, the high majority of people that come and see them, which
          is awesome for me is, they go, "Wow." That's the first reaction but
          really quick on the flip side of that they say, "How?" Their minds
          start ticking, they know what they're seeing, they enjoy what they're
          seeing, but they're wondering what it's about, and how that happens,
          and people start reverse engineering, people start talking about it,
          so it automatically creates a conversation. You get that wow, you get
          that instance of feeling. It's overwhelmingly positive. It's what I
          call an active piece. You're calm and at peace and everything's great
          with the world, but you're also active, it's not lulling you. So your
          mind starts thinking about, what is this, how's it working...and
          seeing different aspects and the colors and everything. So it creates
          a spark hopefully in most people.
        </p>

        <p>
          EA: Yea, the other thing that I want to ask you is, how do you make it
          perfectly circular? It seems like a perfect sphere, I'm wondering how
          do you actually do that?
        </p>

        <p>
          SH: Sure, it's just another technique after many years of of blowing
          glass. Glass naturally wants to ball up. It just wants to do it at
          3,000 degrees. What I try to do is, I try to get everything lined up
          as best I can, and using the viscosity, the flow of the glass to
          finish it up, by lining things up in place. As soon as I have it
          pretty much where I want it, I have to get the surface of the glass
          round while keeping the internal structure set. It's a lot of reading
          temperature, a lot of what I do in the process is make judgment calls
          on temperature base. I want to be able to work the glass when the
          internal temperature is cooler, but I can get the outer temperature
          much, much hotter and roll that, form that into the space by rolling
          it in graphite or wood bulbs. It's kind of a balance, but after I get
          it into that round shape then it's a process of heating it up really
          quick and letting it cool, heating it up really quick and letting it
          cool, and that kind of polishes out the glass, it solidifies it in
          that final percentage into a sphere. But it's many, many years of
          technique and figuring out what works best and which ways. Like I like
          to say, it's always figuring out a way to convince the glass to get it
          where you want it to go, you can never force it, you can never
          manipulate it. You really have to just convince it, work with it, and
          get it in the right place at the right time.
        </p>

        <p>
          EA: What was the first glass thing that you made? You said that you
          were an apprentice to somebody else.
        </p>

        <p>
          SH: The first project I wanted to do is, I had a girlfriend at the
          time, and I wanted to make her a necklace. So I made her a pendant.
          One of the big passions in life is finding your partner, or doing
          something for them. So I wanted to make her something nice to
          strengthen, secure the bond a little bit.
        </p>

        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1664137566/artnow/scott%20hronich/IFG_shelf-sml01-min.jpg"
          alt="Scott Hronich glass art"
        />

        <p>EA: I see, were you successful in making that?</p>

        <p>
          SH: I was. Another nice thing about glass is that it's such a
          fascinating material, that you can make something and be completely
          happy that you went through the process and you made it from the
          beginning to the end. You can start really simple and then it takes
          many, many years to perfect. I can finish out another 25 years and I
          will still have goals. My skill level, my idea level, have not caught
          up to each other. There's just so many avenues and so many different
          ways you can go with glass. And there's so many different things to
          approach with it. It's going to take a lifetime or two just to get
          everywhere you want to go. But at that time I was very excited with
          what I turned out, and she was happy with it too as well. And a lot of
          things with gifts, as far as that, it's more intent than the actual
          turnout of the thing, effort goes a long way for many things.
        </p>

        <p>
          EA: That's nice that that was how you started. Was it something that
          you thought you would be able to do? Or was it like you weren't sure
          if it was gonna work out but you still tried to do it?
        </p>

        <p>
          SH: Funny thing is when I started with it I never really thought that
          it would take me this far really. So I didn't have any expectations
          for it, I never thought I would be able to sell enough to make a
          living. To me that was so far-fetched that I wasn't even thinking of
          it. I was just really in the moment of, this is exciting, this is fun,
          I'm glad I get to play with it, let's see what happens. And it took a
          while before I was like, oh this could actually be- when I got the
          response from people, my progress with it, with the strong art
          background, and the dexterity that I have, it took off quickly as far
          as being able to make a living and sell stuff. But when I first
          started playing with it I was so overwhelmed with the joy of looking
          at it, figuring it out, seeing what it does, and just being creative
          in the moment. That wasn't anywhere near my train of thought, it has
          come many, many years after, and sometimes I still wonder am I gonna
          make it another year, like how far is this gonna go? I celebrated 25
          years this year of complete living off of glass and I have three kids
          and it's still, every step of the way glass is providing a life for
          me, for them. I've gone through two recessions, I've gone through,
          right now going through the war in Ukraine, and the world's in
          turmoil, but I'm still getting support, I'm still getting love for
          what I do. I still have love for what I do, so it almost goes to that
          Field of Dreams thing, "if you make it, they'll come." I can't absorb
          myself with the outside world too much, I just try to stay true to
          what I like doing and let that work its way out. I mean I do work at
          sales, I take it everywhere, I talk to people, I've done a lot of the
          hard work. For me it's really hard to worry and think about the money
          side and everything else, I never did it for money, I need money to do
          it but it's never been the main focus goal.
        </p>

        <p>
          EA: Technically speaking you're not a scientist or a mathematician but
          you still are able to make these things, do I have the right
          impression?
        </p>

        <p>
          SH: Yea, it's funny because I display this physics formula, it comes
          with every piece when I sell a piece of glass. And that comes from a
          professor that teaches industrial optics at MIT and Harvard. He does a
          specialty class between both schools of industrial optics, I met him
          in New York City at the show there and he told me, "it's amazing you
          can do what you do without understanding what you're doing." As far as
          the scientific formulas and the thesis about optics. I was always good
          at math, I loved math when I was at school, but I never took it
          further than algebra, trigonometry, whatever we finished in high
          school. But for me, I just try to build everything artistically
          because I know what I want to see in the glass. So for me it's just
          trial and error, trial and error, trying to get the glass to do what
          it needs to do to get in the sweet spot. An optical depth where you
          look outside the sphere, but lots of color as you go down, it needs to
          lead in there. And it's just troubleshooting, I do a lot of
          troubleshooting. Okay I'm getting this result, why am I getting this
          result instead of this result, you know, and then evaluating, no this
          is the level I want, this is the level I'm getting, there's a thousand
          steps, let's take out 10 steps, let's change them to these steps,
          let's put it back in the formula and try to see if that works to get
          the resolution that I want. So yea, I don't look at it scientifically.
          I wish I would, I really genuinely wish I was "smarter" like I wish I
          was more technical, I wish I could get into the science of the glass,
          and the colors, but in reality I am who I am, and I'm a visual person.
          I see aesthetics and I can tell in a heartbeat what is good and what's
          not, and I just have expectations that I can figure out how to make it
          good, and I try at it, I work at it to get it that way.
        </p>

        <p>
          EA: It seems like you just have a natural talent for what you do with
          the glass and yea it is kind of ironic that it's not any mathematical
          mastermind thing going on.
        </p>

        <p>
          SH: Yea, well I post that out because I use it as a sales, you know so
          people could think about it, but the way I look at it is, math is a
          language and it's a language that some of us kind of speak parts of.
          We all did elementary, high school, we know parts of the language, but
          there's people that are really good at that language and they can view
          the world through that. But it's kind of like a sense, you get a sense
          of of things even though you might not know the full language, you can
          hear the way people express themselves, so you might not know the
          language but you can kind of get a gist of what they're talking about,
          and the feelings and the energy behind it. I would say I'm more one of
          those people that can really read the energy of what's going on,
          artistically.
        </p>

        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1664137566/artnow/scott%20hronich/IFG_Dichroic_Swirl_7in-min.jpg"
          alt="Scott Hronich glass art"
        />

        <p>
          EA: How did you figure out the thing of injecting the metal gas? That
          sounds really technical.
        </p>

        <p>
          SH: Yea, that's been years in the development. When I first started
          that's the only way they colored glass is through what's called
          fuming, heating up gold or silver to the point it turns directly into
          a gas from a solid state. They would use that fume to coat the glass,
          which is again dangerous, you have to have the right ventilation,
          which they didn't have when I started. So that was a trick, but they
          didn't really know how to get the colors out of it, they were just
          kind of trial and error, they were doing their own trial and error,
          but they didn't have any theories on how to do it well. But the piece
          that you're talking about, the galaxy, that came about by a kid, I
          talked to this kid, he had to be between I would say 8 and 12, in New
          York City again at Lincoln Center Square show. He came up to me and
          he's like "this is great, this is beautiful." He's like, "you know
          what'd be really cool though?" He said, "wouldn't it be neat if it
          just looked like the night sky and those were all stars spiraling."
          And like they do in the cartoons I had the epiphany, the little light
          bulb pop up, and I go, I know exactly how I would do that. I know
          really how I can make that happen. I already had a dichroic version of
          that going to create the spiral, so all I had to do is change it with
          the gold and silver. So I just had to figure out how to take the
          material, implement it differently to get that effect. And I'm still
          working on it a little bit, because again, I don't know metals. I know
          people that play with metals, I'm learning more about metals and
          manipulation, but I kind of figured out a way for different
          gradations, different textures in them. And what I do is, how I entrap
          them using colors, because the metals will want to gas off. In my
          darkest galaxies the trick is not to get the metals to gas off at all.
          And that's a big trick because again, everything you're touching and
          working with is at those high temperatures. You're talking 1,875 for
          the glass to start melting. Well metal, silver and gold, melt below a
          thousand degrees, they turn into a gas below a thousand degrees. So
          the trick is, is entrapping them without any of that, or some of that,
          or a lot of that. It's always experimenting, keep playing with the
          material, keep seeing what it does, finding out what conditions are
          out there that changes things. One of the hardest things I figured out
          with metals is, I was talking to a physicist about it and I was like,
          I know, I can get the perfect colors out of silver when it's raining.
          And he's like, that makes perfect sense. And I was like, what do you
          mean? Because to me it was odd, like why would rain, rainy day make
          working with the metals better? And he's like, it has to do with the
          positive ions in the atmosphere, will attract the metals to the glass
          and they probably adhere better. So there's little things that I've
          learned, but a lot of it's talking to other people that have these
          other knowledges, and snowballing it. It's funny because I always
          bring things back to spheres, but it is, all this knowledge base is
          just like a snowball, it's collecting information from here and there,
          and it just keeps trying to add into the one focused area that I have,
          as far as what I make, as far as the depth and the color and the
          spheres.
        </p>

        <p>
          EA: Do you remember when you made your first sphere, like the ones
          that you make now?
        </p>

        <p>
          SH: I'm trying to think, the first one...I can't remember the first
          one. I know what was in it, but I can't picture it in my mind. I can't
          picture what the first sphere was. I know it was probably several
          years after I started blowing glass, four to five years after I
          started blowing glass, before I went into spheres. I know it was
          probably a Seascape because frit was one of the cheaper materials to
          use, which is crushed colored glass. One of the first techniques that
          I developed was a Seascape. Kind of looks, feel of the ocean as you go
          in. Same ideas as the vortex, kind of going down. But I'm not really
          sure what the first piece was, that would be interesting...One of my
          biggest regrets is actually not keeping more of my glass when I first
          started. There was a lot of pressure to make sure I had income to buy
          equipment, it was always you know, get this, I need this equipment, I
          need this material, I need this glass, I need rent. And so I didn't
          start keeping my own work until about 12, 13 years ago. I'd keep a
          piece now and then. I always gave it to families, when I go visit
          family and stuff I'll see really old pieces, because I'd give it away
          as gifts to family for holidays and stuff early on, because I didn't
          have much money, so I would make my gifts, I would make them something
          I think they would like.
        </p>

        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1664137564/artnow/scott%20hronich/IFG_corner-w-three-min.jpg"
          alt="Scott Hronich galaxy glass art"
        />

        <p>
          EA: How do you make the inside of the sphere look like there's a swirl
          effect?
        </p>

        <p>
          SH: Yep. The swirl, what I say is, you have a straight line, you add
          your color, and it could be multiple layers, but everything is
          horizontal, and what you have to do is convince the glass by heating
          it up in a certain way that creates that line to go in, moving in two
          different directions at the same time. And when you get that line to
          rotate in two different directions then you can create the spiral. Of
          course everything I'm doing you're seeing it from the inside, but what
          I'm seeing is from the outside of the glass, because once I add the
          metals I can't see in there, but I watch to see how that outside of
          the glass turns. Even with a straight dichroic vortex, I still have
          the material just gently on the surface on the inside, slightly swirl,
          and I do that for two reasons, I mean, the normal swirls are obvious
          because it just adds more color and goes down, but it's also part of
          the psychology of the piece. If you go straight down and you have
          lines going straight down, you drop to the bottom too quickly, and you
          don't know how far you fell, but if you have to go a spiral it takes
          longer to get there, and it feels deeper. But yea, creating the
          spirals, is heating it up and doing it, but if you get to the dichroic
          swirl, which is the most difficult piece I make...In this piece with
          the dichroic swirl, this is really complicated to get that material to
          really fold, because that's folded over. You can see the material is
          actually folded over, two opposing colors, the blue and the red,
          they're going down at the same rate. So you got to swirl it, that
          material folds over, and then you want it to swirl faster at the
          bottom...So it's really difficult because you have to really have
          dexterity to be able to do that, you can have the theory, but then you
          physically have to rotate at different times, and different things,
          and line up the heat just right, staged. It's not just you can heat it
          right up and go, it has to be staging up the heating up of the
          material to get it to flow in the right place. And then when you round
          it off you cannot ruin anything that you added in there, so it gets a
          little tricky.
        </p>

        <p>
          EA: It sounds really complex. The dichroic thing is actually metal
          inside of there?
        </p>

        <p>
          SH: Yea, it's a crystallized metal. It's actually grown on a sheet of
          glass, and the reason it's grown on glass in the first place wasn't
          for art, they were looking for a material that they could put in a
          high pressure chamber that could withstand two things: high pressure
          and high temperature. Glass is perfect for both of those things. Glass
          is really strong and it can withstand a lot of temperature... from my
          understanding, I was just at Corning Glass and they used this, said,
          dichroic was developed for NASA to reflect a lot of the heat and light
          back when entering the atmosphere again, or something like that. So
          they weren't making it for art, they were making it for science. They
          had a special need for it. So one of the people that helped develop it
          took it back to his home studio and he was just playing around with it
          and started making things and discovered it made fascinating color in
          glass. And I'm lucky it did, because when I started 25 years ago-
          borosilicate glass, the colors that we had were pretty basic, they
          were pretty boring, there wasn't much color in them at all, because
          the higher quality glass that you have, the higher it goes, the lower
          the coefficient expansion goes. So how much it's going to move as you
          heat it and cool it, and it had a very low one, and there wasn't as
          much of a need as soft glass. Soft glass was everywhere...they had
          lots of scientists, lots of people developing colors, so borosilicate
          had a lot slower process of getting there. One thing I became
          proficient at, because as a guy that wanted to paint, was a lot of
          color, and the only way to get that vibrancy was using dichroic. And
          dichroic is all the same crystal, it just depends how thick the
          crystal was grown. So the thicker the crystal growth- it goes through
          the light spectrum and the larger it is it changes the actual color,
          because it's reflecting the color back to your eye differently, but I
          became proficient at it and I use a lot of it, and it's a good thing
          to have.
        </p>

        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1664137569/artnow/scott%20hronich/Pernicka_Comets_Planets_5in-min.jpg"
          alt="Scott Hronich glass sphere"
        />

        <p>EA: What was NASA using it for?</p>

        <p>
          SH: I think it was the Space Shuttle is what they were using it for,
          tiles, they wanted to put special tiles on to reflect heat.
        </p>

        <p>
          EA: Oh huh...that's interesting. When you say it's really difficult to
          make, is it like, do you ever mess up while you're making it?
        </p>

        <p>
          SH: I don't make the dichroic itself, I buy those from companies that
          have the equipment to do it.
        </p>

        <p>EA: I mean when you're making them into a sphere?</p>

        <p>
          SH: Yes, there's several ways, the main thing is burning it out, which
          means you're turning that crystal into ash, you're destroying the
          crystal and you're losing all the color. That's fairly easy to do and
          you can burn it out once it's already in the glass too. You can
          overheat it, you can heat it up too much. When you move glass, as I'm
          saying you're doing that, actually that moving of the glass if you
          stretch it, that moving glass actually creates more heat, makes it
          warmer quicker. So if you move it really fast, you move it too much,
          you can burn it out as well. So yea, it's kind of a delicate material
          to use as color. It's not consistent, even buying it is inconsistent,
          because making it is inconsistent. I have quality control issues with
          the production of dichroic. It's man-made so there's going to be flaws
          in the material, there's going to be good sheets, there's going to be
          bad sheets. You got to learn how to read the sheets when you get them
          and then cut them up and use them depending on how the sheet lays and
          how it looks. Just something you figure out after many, many years of
          using it.
        </p>

        <p>
          EA: So earlier in your life when you thought about your future you
          didn't think that you would be where you are right now?
        </p>

        <p>
          SH: Not at all, not at all. Where I grew up it was hard for people to
          make a regular living more or less in art. It was a very rural town in
          New Mexico. So very practical people, a lot of ranchers, a lot of
          farmers. My mom was a school teacher, practical work. Art wasn't a
          consideration, it wasn't available. There wasn't much culture for art
          there. Ironically I was born in Santa Fe, which at the time when I was
          in school was the third largest art market in the world. Lots of art,
          lots of culture. But I grew up with a practical mindset of, this is
          possible, this is not, this is not for you. When I went to university,
          I wanted to be a painter, that's what I was there for. My painting
          teachers in the art department at the time said, "painting is dead,
          you can't make a living at painting." Which at the time, when you're
          sitting there paying for a university, saying, "it's okay you want to
          paint, but you got to figure out a different way to live" that was
          pretty discouraging. So even at university I didn't think, I didn't
          feel that art was possible, unless you did something practical like my
          teachers were doing, they were teaching art. So it's nice that I've
          been able to do it and the ironic thing is, I guess I meet a lot of
          parents, a lot more people with kids since I have young children and
          interacting with them, so many people are more excited about what I
          do, because they appreciate it more, because a lot of people are doing
          jobs they don't love, they're doing a job because they're doing the
          practical thing. They need to make a living, they have
          responsibilities to take care of, their goal is to get through and
          make it to retirement and I'm just lucky that, you know, I mean it
          came through a lot of hard work, but it's also luck that I am getting
          to do what I do, something I really enjoy that also is paying all the
          bills and letting me have a life besides working, which I'm pretty
          grateful for, thankful for.
        </p>

        <p>
          EA: Yea it kind of reminds me of this poem that I read. There's a guy
          who's a professional poker player his name is Phil Hellmuth, and he
          wrote this book about how to be a good poker player. I bought the book
          randomly, there's a poem in the beginning that he wrote and it's a
          totally random discovery that I found his poem. Anyway the poem is
          called The Universe Conspired to Help and it has a really interesting
          line, it says, "Once he took the first step down the line, the
          universe conspired to help make sure he was fine. He never dreamed he
          would accomplish so much that the Universe would give him such
          incredible luck. Now older and wiser he understood the hardest part
          was convincing himself that it was time to start." Maybe that's a
          timeless thing that people experience, that sometimes just trying to
          start- taking the first step sometimes is all that's necessary, but it
          could also be really difficult.
        </p>

        <p>
          SH: Sure, yea, and I think continuing to do the steps, even though,
          like I said, the world falls apart. And you don't see how somebody's,
          you know, people have a lot of things to worry about, how is somebody
          gonna spend money on something extra? But sometimes that's the time
          they need that art the most, they need something in their life that
          brings a little magic and brings a little brightness, a little hope,
          and a little bit more love in their life. So it's, yea, when all else
          fails, to still do what you're doing, you're doing good things, in the
          end hopefully it all comes out.
        </p>

        <p>
          EA: Are you the the only glass artist that you know of that does what
          you do? Like the style that you do everything, like the glass
          vortexes?
        </p>

        <p>
          SH: No, there's other people that are doing it as well. I know of at
          least two others that are on the same path as me.
        </p>

        <p>
          EA: I see, what about the galaxies and everything else that you make?
        </p>

        <p>
          SH: Not the spiral galaxies no. There are two people last year that
          figured out my dichroic swirls which used to be my signature piece
          because I was the only one that I knew of that knew how that was made.
          Like I said, it's the most difficult thing I make. One of them
          messaged me through one of the social media and said, sorry about this
          but I figured it out. And I'm like, you know, I hope you enjoy it it's
          hard, it's a trick. And they're like, I didn't want to tell you, and
          I'm like, I appreciate you telling me, but if you can do it, you can
          do it. And so it's interesting, especially in today's age because it's
          the information age, internet, everything's possible. When I started,
          if you walked into a glass blowing studio and they knew you were doing
          glass, everybody would shut down and stopped what they're working, it
          was very secretive. And now people are sitting with their phone while
          they're blowing glass, every time they blow glass they try to get as
          many people to watch them as they can. It's just such an extreme
          change in the 25 years, which is probably for the better. The ability
          of people is rocketing off, as far as what type of glass is being made
          and the quality and everything, it's because people decided to switch
          that around. Instead of being secretive, be completely open, and
          they're making better glass, but it's such a shock. I'm still working
          by myself, I work privately, I'm coming up with my own work, my own
          designs and trying to figure them out, and I have some stuff that I'm
          doing that nobody else is doing, which is great. What I tell myself
          is, I gotta be faster, I gotta make them better, I gotta make them
          bigger, and then hopefully that'll keep me surviving. I still have a
          good group that are following and I hope I can make it another 25
          years.
        </p>

        <p>
          EA: I also want to ask you, you have some pieces where there's a
          gemstone floating in the middle or an opal, I'm not sure what it is.
        </p>

        <p>
          SH: Sure, it's the Gilson opals, and I was lucky to find out the
          story, because when I was making them and introducing the opals, I saw
          other people using the opals in there. I wanted to use them too.
          Through the supply chain I was getting really bad opals. And so I met
          a guy, Gerry at Manning International in Connecticut, he was the
          national distributor for them. So I took in my work and I said, look,
          I'm trying to make these really nice glass pieces and I'm trying to
          put these in there. To make the nicest sphere I need the nicest opals.
          So he sat me down, he's an amazing guy, and he's like, okay well, I'll
          tell you the history of them. So Gilson opals were made by this guy
          Gilson who discovered how opals grew in nature. Scientist guy, and
          what he did is he tried to mimic it in a laboratory, so he figured out
          how to grow opals, in high pressure, high temperature, inside kilns.
          He brought it to Gerry and said, I have this product I need to
          distribute it, I want you to sell these man-made opals for me. And he
          wouldn't do it, he's like, no they're not the quality I need right
          now. People were trying to make synthetic opals through plastic and
          resins, and something that was more opal that was reliable. So he
          ended up improving the technique and Gerry bought his product, got the
          rights for world distribution...So he had an amazing collection of
          opals, we went through them and talked about them, and that really
          improved the quality of work that I had as well. So yea, main thing
          I'm doing right now with them is, I'm doing two things, opal planets
          in my space scenes, because I went from the galaxy ones, the spiral,
          to open stars, into like galaxy, nebulas; the space scenes went a lot
          further. So what I'm doing is placing in opals for planets. I get
          sphere-cut opals, I get different colors. And when you move back and
          forth, the colors of the opals change, and therefore it just really
          gives that whole planet feel to them. But I'm also using gradations of
          opal, different crushed opals, different sizes of them. I can use them
          for meteors, I can use them for dust. So right now the pieces that I'm
          making, even in the galaxies like you have, I'm adding a little of the
          opal dust, and when you move it you get this twinkle of color and it
          looks like stars are twinkling at you, just like they do in the night
          sky, it's pretty amazing. So yea, when I added the gemstones to it,
          they're Gilson opals, they're laboratory grown opals, they're not
          synthetics- synthetics are plastics which will melt. These are gem,
          high quality opals, they just have zero water content. Natural opals
          have water and these have no water, so they're compatible. You still
          can burn them out if you don't put them in right, not as hard to do as
          dichroic, but still possible, they still can fracture. They create a
          lot of pressure in the glass, but still capable of them. So that's
          something that you can enhance the glass with.
        </p>

        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1664137570/artnow/scott%20hronich/Pernicka_Gold_Silver_Galaxy_5in-min.jpg"
          alt="Scott Hronich space glass sphere"
        />

        <p>
          EA: Also what I was wondering about is, you have a piece where it
          looks like it's floating in the center, how do you do that?
        </p>

        <p>
          SH: Yep, so basically setting the opal in there. And usually if I have
          it in a vortex, I used to put them in most of the vortexes, I don't
          put them in as much anymore, but yea in the vortexes.
        </p>

        <p>
          So basically I would start with clear glass, heat it up and place the
          opal at the bottom of one rod while adding another glass rod behind
          it. So it's basically entrapping it, and adding more clear glass and
          building it up will center the opal, and go from there. So that
          gemstone is actually trapped inside the clear glass from the
          beginning. The trick is to keep it centered and where you want it the
          whole time, because you're always rotating and moving the glass. So
          you can get it cockeyed, or over to one side or to the other side, but
          when you work on your technique and you concentrate on it, you can get
          it centered really nice. If you get it in the center of the sphere
          then when you rotate it, it just looks magical rotating in there, when
          you rotate the sphere.
        </p>

        <p>
          EA: I'm sure it looks a lot better in person but the photos and videos
          that I've seen it's really magical and it's just kind of baffling,
          well all of your pieces are baffling, but yea, it seems like there's
          something special to those for sure.
        </p>

        <p>
          SH: Yea, it's nice to add. They add a lot option of color, they add
          magic, because they do look like they're floating. And a lot of that
          happens because you can only use these in borosilicate glass, soft
          glass is too weak for them, they'll shatter, different coefficient of
          expansion. But since borosilicate is so optically clear, you can make
          them look like they're just floating in space, and that's one of the
          big wow, after wow, how questions that I get. How are those floating
          in there? Because people don't see where they're trapped, they look
          like they're actually floating, it really kicks in that magical
          effect.
        </p>

        <p>
          EA: Yea definitely. I was talking to my friend last night and I was
          showing him some of your spheres and the work that you do, he said it
          reminds him of the movie Men in Black with the galaxy on Orion's belt,
          he wanted me to to tell you that.
        </p>

        <p>
          SH: I get that a lot. Again I see a lot of people at shows, and that
          is a moment in cinema history that touched so many people. I was the
          same way, I'm a big sci-fi space fan, I watched the movie. When that
          part of the movie comes up and you look into that little ball in the
          cat's neck and it turns into all these galaxies, it's just an amazing
          moment. When they made that they made gold and it affected so many
          people. I hear that a lot at the shows, it connects with them. The
          joke is, which came first? And I don't know the exact date I started
          the galaxy necklace but it wasn't inspired by that, but it does give
          people that feel which is amazing, because something as stagnant as
          glass compared with the motion picture you know, and animation, for
          sure to be able to be compared to that and get that same feeling, it's
          a good compliment.
        </p>

        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1664137568/artnow/scott%20hronich/Pernicka_Comets_Planets_2in-min.jpg"
          alt="Scott Hronich glass art display"
        />

        <p>
          EA: How did you start working with the magic shop that's here in San
          Diego, Art of Play?
        </p>

        <p>
          SH: Yea, Art of Play. I wholesale to a lot of places, and I'm not sure
          how they found me because I used to do a wholesale show, the Rosen
          show, many years ago in Philadelphia. But after 2008 there weren't as
          many galleries being able to survive off of American craft. They
          contacted me and asked if they could purchase wholesale. I set them up
          with an account and they've seemed to do fairly well off of them.
          They've ordered several times, and they're nice people, great people.
          So I'm not really sure how they found me. They saw it, they figured it
          would work. I love the idea of magic, magic show, magic cards,
          everything. Because that's what I joke about at the shows, it's like
          well, when people are like, how do you do that? Well do you want math
          or do you want magic? It kind of gives that magic feel. So I like
          them, I'm not sure how they found me but I appreciate it...It's
          amazing how it gets around, how people are connected with it. I had
          some viral videos happen about six years ago. I had 24 million views
          in 24 hours on a combination that Bored Panda did, when Bored Panda
          just became a hit on social media, and the true viral thing happened.
          It was just everywhere all at once, and I'm still getting ripples from
          that. So I don't know if it was from that, people have seen it, so I'm
          thankful for it. I'm thankful for their support, keeps giving me the
          ability to make more so other people can get it, plus also build the
          next big thing too.
        </p>

        <p>EA: How did you come up with the name Internal Fire Glass?</p>

        <p>
          SH: That actually came from, kind of what we're talking about is the
          people's excitement, internal fire, it was my creative energy. Like
          the fire that gets you going, that moment that gets you out of bed, it
          gives you something that you want to do, the passion, the fire. And to
          me it was kind of a working relationship, I noticed that my energy and
          my creativity was amplified in making it with the person that was
          viewing it, and connecting to it, and purchasing it. So to me it was a
          combination between the collector and the artist, they had internal
          fire. The collector is sitting there finding something that they're
          passionate about and when they get it they're excited, they're happy,
          they're handing me that energy. I'm sitting there making something
          with that energy, presenting it, somebody else is picking that up and
          they're getting energized by it. And it's just trying to keep that
          internal fire going inside you, inside me, as the creator, as the
          collector, as people.
        </p>

        <p>
          EA: Yea, I really like that name. And I think that kind of just adds
          to everything, like the whole special feeling that I have about your
          pieces. The name Internal Fire Glass makes it extra special for some
          reason.
        </p>

        <p>
          SH: I appreciate that. I enjoyed it, I don't think about it as much
          anymore as far as the name, it's just what it is. But when I'm asked
          about it, it just kind of brings that back up and you know a lot of
          people wonder if it is because there's fire inside the glass. There's
          that color...I think art does reflect life, and the more energy, the
          more color, the more fire I have, the better the glass turns out, the
          more excited you get, it works.
        </p>

        <p>
          EA: Yea that's a really good point actually. I was wondering do you
          believe in life in the universe? Or some sort of other worlds beyond
          Earth, or something like that?
        </p>

        <p>
          SH: Oh yea, I'm positive. I feel like sometimes we're the Who, you
          know we're on the little speck of dust and there's a whole bigger life
          out there that we're just not aware of. I believe that we as a
          species, we'll find that. Hopefully we can evolve enough to be able to
          do that. I think we have a long ways to go in our evolution but I hope
          we can explore, and I wish I was a part of it, getting to explore
          space, getting to explore other planets, getting to do that. I believe
          that's the best thing for us, I think that's what we're meant to do. I
          think just through our knowledge base and how we've grown and how we
          can understand the universe spiritually, mathematically, practically.
          I think we have a big lesson to learn before we leave Earth, and
          hopefully we figure out everything we need to know so when we leave we
          can explore and do a good job when we go out, and I personally can't
          wait. Hopefully it's, I don't like Star Wars because it's fighting, I
          hope we get out there and we find a more evolved way to be besides
          fighting other creatures and other civilizations...But I'm sure it's
          out there, it's all a matter of time and we're headed that way.
        </p>

        <p>
          EA: Yea I totally agree with that. Hopefully we could evolve so that
          if we do ever explore the universe it'll be more about learning and
          that sort of thing. And then I guess like mathematically it has to be
          possible, because there's millions or billions of galaxies, and so
          just by that alone, there has to be something besides ourselves.
        </p>

        <p>
          SH: Oh yea, yea. I mean, and just what we can see and not see, size is
          a huge difference. You know, where with going micro you can see so
          many living organisms that we just, it's unbelievable how much life is
          on our planet. And our definition of life will expand, I'm sure. It's
          going to be an amazing world in the future as long as we can get along
          and evolve, and we can share resources and knowledge, and we can do
          something great. I hope it's that way for my children, my son, he
          wants to be an engineer and an astronaut and I hope he gets to do it,
          I hope he gets to enjoy that and explore that.
        </p>

        <p>
          EA: Bertrand Russell said one of the most important things was for
          people to learn to tolerate each other and to get along with each
          other, so it's definitely something that hopefully will be in our
          future.
        </p>

        <p>SH: I hope so, I really do.</p>

        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1664137567/artnow/scott%20hronich/IFG_Booth-2016_8x12-min.jpg"
          alt="Scott Hronich glass art display"
        />

        <p>EA: Are your spheres in any museums?</p>

        <p>
          SH: Yes, well I'm usually in the museum to sell, not museum
          collections. Corning Museum was selling them, Guggenheim was selling
          them in their shop, Kobe Museum in Japan was selling them, or they
          still have one left, but they're ordering, they're going to be doing
          another marble show, I'm contacting them, so they're going to be
          there. There was another one up in, I can't remember, there's another
          one in Canada. So yes, but I don't know as far as collections. My goal
          jokingly was, there's a 12 inch glass sphere in Corning Museum of Josh
          Simpson, which, I'm a big fan of his work, I always have been. He
          makes planets, he's a soft glass artist. It took 15 people, he told me
          the story, like 15 people, it's 155 pounds of glass, just a huge, huge
          feat to make a 12 inch sphere. Borosilicate again melts at a higher
          temperature. I'm up to 9 inches by myself right now, I'm hoping since
          I'm a New York artist and I'm using Corning Pyrex glass that maybe one
          day I'd be able to get in their museum, as far as making the world's
          largest borosilicate. I'm hoping I can sit beside him before I'm done
          in my career with a 12 inch sphere next to his in the museum. They
          have a world-class museum there, it's amazing, I just took my kids
          this summer. Amazing collection of work and I would feel very honored
          to be amongst all the collection in there, I really would.
        </p>

        <p>
          EA: I could see your pieces in a museum, that makes sense to me, so
          hopefully at some point that'll happen. I have, let's see, I think
          four more questions here. One of my questions is, how do you deal with
          stress?
        </p>

        <p>
          SH: That's a good one. Yea, exercise. Exercise is my best stress
          relief, and cooking. Eating well, and exercise. I enjoy cooking, I
          enjoy gardening. Growing the food gives you exercise, it's physical.
          Going in and cooking the food that you grow is elating when it's more
          nutritious. It's just a sense of pride, you've waited the time, you
          put the work in and then you get the reward. So those are my two
          biggest things. I used to do yoga, too busy, I just haven't taken the
          time. But exercise, that's what I was doing with soccer. Trying to
          create a social moment to get exercise and be with people, friends,
          that helps as well. So my life is super stressful as far as everything
          that's going on, but those things really help balance that out.
        </p>

        <p>EA: Nice, yea. Do you do any sort of meditation or anything?</p>

        <p>
          SH: Generally I would say no, but one thing I'm trying to do is really
          change my posture. What's happened is that I'm working like this, so I
          start really getting a lot of tension in my shoulder right there and
          my neck's going forward. So what I've been doing are a lot of
          exercises, either laying on the floor, hanging my head off the end of
          the bed, trying to reverse that motion. And when I'm doing that I'm
          sitting there for 10 to 15 minutes trying to do nothing else but
          realign my spine and to get it in a healthy position. And so I guess
          in a way I'm starting to, because I'm doing nothing else in those 10
          or 20 minutes. I'm trying to just focus on, I know this is going to be
          better for my health, it's going to feel better, and giving myself
          that time to do it. But it's not intentionally like, I'm
          meditating...I actually am doing something, I'm trying to realign my
          spine, but I'm just leaving it at that and letting my mind rest, and
          not think of anything else. I don't fall asleep, I just don't do
          anything else. And it's helped, I've noticed, I've been doing this for
          the last two weeks and my alertness, my awareness has been heightened,
          it is, it's just higher. So it's good. I believe in it, I believe in
          its effectiveness, that's the only way I'm doing it right now.
        </p>

        <p>EA: What kind of art inspires you in your own life?</p>

        <p>
          SH: I think there's lots of beautiful moments that are just art in the
          world, natural art. But I get inspired by other artists doing things
          as well. Paintings, I still would love to be able to be a painter one
          day, I love the beauty in it. But I love cinema too, I love movies, I
          always have, I always will. I love books, I listen to books while I
          work, I love written word, I love people's imaginations. I'm going
          through the Wheel of Time right now, I'm a big Dune fan, so I listen
          to those. I love other people's creativity as long as it's in a
          positive way, a beautiful way. Doesn't mean it doesn't have to have
          some kind of pain involved in it, but just anybody trying to create
          something that's beautiful in the end. You can go through trouble, you
          can go through that, but the end result is something inspiring or
          beautiful... That kind of helps me, inspire me, and it does the things
          that I'm trying to create for other people, it really ups my day, it
          envelops my heart, it strengthens my courage to live and be part of
          the world, it does.
        </p>

        <p>
          EA: Yea, definitely. It kind of reminds me of what John Frusciante
          says in the beginning of this one documentary about the Red Hot Chili
          Peppers. He said if he spends too much time thinking about all the
          terrible things in the world that he would never have the energy to
          make the music that he does.{" "}
        </p>

        <p>
          SH: I can see that, you got to be aware that it's going on but you
          can't focus on it, not where you're at, you got to do the best you can
          where you are at. And hopefully the change is coming through that.
        </p>

        <p>
          EA: Do you feel that all you touch turns to gold, as in things are
          always easy? Or is it like, you feel everything's just a result of
          continuous work?
        </p>

        <p>
          SH: It's continuous trying, trial and error, trial and error, it's
          continuous. There's nothing set for me at all. There's no safety net,
          I always have to try, I always have to go, I always have to put the
          effort in. I have lots of things that don't turn out. I have a whole
          table full, I don't know why I have these up here right now on my
          desk, but I have a table full of stuff that just didn't work out. I
          guess I was separating them from the rest of the stuff. I have a lot
          of mistakes and I make a lot of mistakes, there's a lot of things,
          there's avenues I could have done better on and things, so it's always
          a struggle. I need that in a way though, if it was easy it wouldn't be
          interesting, and I wouldn't be engaged. So I need it, I don't need it
          to be extremely difficult, but I need it to be a challenge, and it is
          a challenge.
        </p>

        <img
          src="https://res.cloudinary.com/benjifri/image/upload/v1664137570/artnow/scott%20hronich/Pernicka_DichroicVortex-wOpal_4in-min.jpg"
          alt="Scott Hronich Glass Sphere Art"
        />

        <p>
          EA: My other question, this is a two-part question here, the first
          part is, do you think that you're a genius? And the other part of that
          is, do you believe in the idea of a masterpiece?
        </p>

        <p>
          SH: I'm the first person that would say I wish I was much smarter than
          I am. I've met really smart people and I'm jealous of it, I wish I had
          more of that kind of a mind, especially for facts and information.
          That would be great, that would be amazing. What was the second part
          of the question?
        </p>

        <p>EA: Do you believe in the idea of a masterpiece?</p>

        <p>
          SH: Yea, yea, oh yea. Just like that moment we were talking about in
          the movie, the Orion's belt, that was a masterpiece within the movie.
          It was that one segment. The movie was fun, it was great, I don't
          think the whole movie was a masterpiece, but that moment, you know it
          all came into that one moment, that is amazing. I think Dune the book
          is a masterpiece. I think there's paintings out there that break my
          heart when I look at them, they're masterpieces, you couldn't change
          anything. There's some songs that hit every note, hit everything. You
          can get to moments of great art, or masterpiece, whatever you want to
          call it, great moments in time that are encapsulated. I think it's
          possible and I think it's nice that humans are striving for such
          things and are able once in a while to get them. And I feel bad that
          there's people that probably put in effort, they want that and maybe
          they don't quite hit the mark, but at least they're out there trying
          and making it. I don't think I've mastered glass yet, I think I have a
          ways to go. I think it's possible, maybe I'll have a great
          masterpiece. I have one piece I'm really excited about that I've done,
          I've created. But as soon as that one's made, I gotta make the next
          one. And I'm not going to make a masterpiece every time, I just have
          to keep trying to get as good as I can. And my joke when I do shows, I
          joke about this- I always wanted to be a well-rounded deep person and
          it's keeping a balance in your life that gives you the ability to do
          your best every day. Just gotta try to keep an even balance, keep you
          in a good state where when you go to work, when I go to make
          something, when I go to blow glass that I can do my best when I'm in
          that moment. I'm at a spot right now where I'm at a good pretty good
          balance of it. Last two weeks has been beautiful work. So I don't know
          when it's all said and done and I'm gone, if they can look at my work
          and say it's a masterpiece, or a period of time was masterpiece, I
          don't know how they'll look at it, but that's for somebody else to
          judge, not me.{" "}
        </p>

        <p>
          EA: That was really well said, and a lot to think about there. Anyway,
          we could wrap this up, it was a really great conversation, I really
          enjoyed talking to you. It seems like maybe we just barely scratched
          the surface but I think we also touched on a lot of good things as
          well.
        </p>

        <p>
          SH: Yea, it was a pleasure Edward, I appreciate you taking the time
          and getting me involved, thank you.
        </p>

        <br />
        <br />
        <br />
        <br />

        <h3>
          <a href="/">artnow.world</a>
        </h3>
      </div>
    </Layout>
  );
};

export default Scott;
